import $ from 'jquery';

$(".js-question").click(function(){
  displayDiagnosis('#qe1');
});


function displayDiagnosis(id) {
	if (id == 'undefined' || id == '' || id == null) return false;
	$('body').css({ 'overflow': 'hidden' });
	var $div = $("<div class=\"open-modal\"></div>");
	var html = "<div id=\"\" class = \"open-modal-container\">";
	var resultClass = id.indexOf("brand") != -1 ? " class=\"result\"" : "";
	html += "<img src=\"../../assets/img/fun/kometsubu/diagnosis-window.png\" id=\"modal-map\""+ resultClass +" data-id=\"hb\" usemap=\"#modal-map\" border=\"0\" sytle=\"display: none;\" />";
	html += "<div id=\"diagnosis\""+ resultClass +"><\/div>"
	html += "<\/div>";
	$div.html(html);
	$('body').append($div);
	$('#diagnosis').load("/diagnosis.html" + id.replace("#", " \#"));
	setTimeout(function () {
		ImageMap('img[usemap]');
		var _this = $div;
		var modalImg = _this.find('.open-modal-container > img');
		if (modalImg != null && modalImg != 'undefined') {
			if (modalImg.height() > 0) {
				setDiagnosisModalPosition(modalImg);
			} else {
				modalImg.off('load').on('load', function () {
					setDiagnosisModalPosition(modalImg);
				});
				$(window).off('resize').on('resize', function () {
					setDiagnosisModalPosition(modalImg);
				})
			}
		}
		_this.ready(function () {
			setTimeout(function () {
				$('body').find('.diagnosis-content__answer').off('click').on('click', function (e) {
					_this.remove();
					$('body').attr('style', '');
					var dataId = $(this).attr('data-id');
					if (dataId) {
						displayDiagnosis(dataId);
					}
					e.stopPropagation();
				});
				$('body').find('.diagnosis').off('click').on('click', function (e) {
					e.stopPropagation();
				});
				$('body').find('.open-modal').off('click').on('click', function () {
					_this.remove();
				});
			},250);
		});
	});
}

function setDiagnosisModalPosition(modalImg) {
	var winHeight = $(window).innerHeight();
	var imgHeight = modalImg.height();
	var marginTop = parseInt(winHeight - modalImg.height());
	if (marginTop >= 250)
		marginTop = 180;
	modalImg.css({ 'margin-top': (marginTop / 2) + 'px' });
	$('#diagnosis').css({
		'margin-top': (marginTop / 2) + 'px',
		'height': imgHeight + 'px'
	});
}
