import $ from 'jquery';

var target = window.location.hash,
	target = target.replace('#', '');
  history.replaceState(null, null, ' ');
$(window).on('load', function () {

	if (target) {
		var offsetTop = $("#" + target).offset().top - $('.c-header').height();

		$('html, body').animate({
			scrollTop: offsetTop
		}, 1000, 'swing', function () {});
	}
});

$('a[href*=\\#]:not([href=\\#])').click(function () {
	if (
		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
		location.hostname == this.hostname
	) {
		var $target = $(this.hash);
		$target =
			($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
		// var offsetTop = $(".c-header").height();
		if ($target.length) {
			if ($('body').hasClass('is-fixed') && $(this).hasClass('js-modal-anchor')) {
				$('.js-modal2-modal').trigger('modal-close');
			}
			var targetOffset = $target.offset().top - $('.c-header').height();
			$('html,body').animate({ scrollTop: targetOffset }, 1000);
			return false;
		}
	}
});

// Accor2 for c-block28__list
$(function() {
	const accor = $('.js-accor2')

	if(accor.length) {
		accor.each(function() {
			const _thisAccor = $(this)
			const items = _thisAccor.find('.js-accor2-item')
			const content = _thisAccor.find('.js-accor2-content')
			const btn = _thisAccor.find('.js-accor2-btn')
			let margin = 40

			function handleHeightItems(screen) {
				let maxUp = 0, maxDown = 0, maxH = 0
				let a, b = 0

				if(screen === 'pc') {
					a = 4
					b = 8

				} else {
					a = 2
					b = 4
				}

				for(let i = 0; i < a; i++) {
					if(maxUp < $(items[i]).innerHeight()) {
						maxUp = $(items[i]).innerHeight()
					}
				}

				for(let i = a; i < b; i++) {
					if(maxDown < $(items[i]).innerHeight()) {
						maxDown = $(items[i]).innerHeight()
					}
				}

				maxH = maxDown + maxUp + margin
				content.css('height', maxH)
				maxUp = 0, maxDown = 0, maxH = 0
			}

			function toggleHeightAccor() {
				if(items.length < 9 || items.length < 5) {
					content.removeAttr('style')
				}

				if($(window).outerWidth() > 767) {
					handleHeightItems('pc')

				} else {
					handleHeightItems('sp')
				}
			}

			toggleHeightAccor()

			$(window).on('resize', function() {
				if(!btn.hasClass('is-active')) {
					setTimeout(() => {
						toggleHeightAccor()
					}, 100);
				}
			})

			btn.on('click', function() {
				if(btn.hasClass('is-active')) {
					toggleHeightAccor()
					$(this).removeClass('is-active').text('もっと見る')

				} else {
					content.removeAttr('style')
					$(this).addClass('is-active').text('閉じる')
				}
			})
		})
	}
})
