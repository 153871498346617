import $ from 'jquery';

$('.js-accordion').on('click' , function(){
  if($(window).width() < 768){
    if($(this).hasClass('is-active')){
      $(this).removeClass('is-active');
      $(this).find('.c-list35__a').slideUp();
      $(this).find('.c-list35__img').slideUp();
    }else{
      $(this).addClass('is-active');
      $(this).find('.c-list35__a').slideDown();
      $(this).find('.c-list35__img').slideDown();
    }
  }
});
