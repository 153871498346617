import $ from 'jquery';

$(document).ready(function() {
	// Call the effect handlers as soon as the page is loaded
	scroll_effectTtl();
	scroll_effect();
	img_effect();
	scroll_effectFadeIn();

	// call event
	$(window).on('scroll load resize', function() {
			scroll_effectTtl();
			scroll_effect();
			img_effect();
			scroll_effectFadeIn();
	});
});

function scroll_effectTtl() {
	$('.js-fadeInTtl').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).height();
		if (scroll > elemPos - windowHeight + 50) {
			$(this).addClass('scrollIn');
		} else {
		}
	});
}

function scroll_effect() {
	$('.js-fadeIn').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).height();
		if (scroll > elemPos - windowHeight + 50) {
			$(this).addClass('scrollIn');
		} else {
		}
	});
}

function scroll_effectFadeIn() {
	$('.js-fadeIn2').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).height();
		if (scroll > elemPos - windowHeight + 50) {
			$(this).addClass('scrollIn');
		} else {
		}
	});
}

function img_effect() {
	$('.js-imgEffect').each(function () {
		let elemPos = $(this).offset().top;
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).height();
		if (scroll > elemPos - windowHeight + 50) {
			$(this).addClass('is-active');
		} else {
		}
	});
}




