import $ from 'jquery';
$('.js-video').click(function () {
	let video =
		'<iframe src="' + $(this).data('youtube') + '" frameborder="0"></iframe>';
	$('.js-video-frame', this).replaceWith(video);
	$('.js-video-title', this).remove();
});




$('.js-mv_video').on('click', function(){
	if($(this).hasClass('on')){
		$(this).removeClass('on');
		$('#mv1_video').get(0).pause();
	}else{
		$(this).addClass('on');
		$('#mv1_video').get(0).play();
	}
})
