import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

$(function () {
	if ($('.js-slider_mv1').length) {
		$('.js-slider_mv1').each(function () {
			let domThis = this;
			const slider_mv1 = new Swiper(domThis, {
				loop: true,
				effect: 'fade',
				speed: 2000,
				slidesPerView: 1,
				autoplay: {
					enabled: true,
					delay: 2000,
					disableOnInteraction: false,
				},
			});

			$('.c-mv1__btn').click(function () {
				// Nếu đang autoplay, dừng lại; nếu không, bắt đầu autoplay
				if (slider_mv1.autoplay.running) {
					slider_mv1.autoplay.stop();
					$(this).text('再生する').removeClass('on');
				} else {
					slider_mv1.autoplay.start();
					$(this).text('停止する').addClass('on');
				}
			});
		});
	}

	if ($('.js-slide1').length) {
		$('.js-slide1').each(function () {
			const slider1 = new Swiper(this, {
				loop: true,
				centeredSlides: true,
				watchSlidesProgress: true,
				slidesPerView: 1.14,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				breakpoints: {
					768: {
						slidesPerView: 2.365,
					},
				},
			});
		});
	}

	if ($('.js-slide2').length) {
		$('.js-slide2').each(function () {
			const slider2 = new Swiper(this, {
				slidesPerView: 'auto',
				centeredSlides: true,
				roundLengths: true,
				loop: true,
				autoplay: {
					delay: 2000,
				},
				loopAdditionalSlides: 30,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				breakpoints: {
					320: {
						spaceBetween: 10,
					},
					768: {
						spaceBetween: 30,
					},
				},
			});
		});
	}

	if ($('.js-slide3').length) {
		if($(window).outerWidth() < 768) {
			$('.js-slide3').each(function () {
				const slider3 = new Swiper(this, {
					slidesPerView: 'auto',
					centeredSlides: true,
					roundLengths: true,
					loopAdditionalSlides: 11,
					spaceBetween: 7,
					loop: true,
					pagination: {
						el: '.swiper-pagination3',
						clickable: true,
					},

					navigation: {
						nextEl: '.swiper-button-next3',
						prevEl: '.swiper-button-prev3',
					},
					breakpoints: {
						768: {
							spaceBetween: 11,
						},
					},
				});
			});
		}
	}

	if ($('.js-slide4').length) {
		$('.js-slide4').each(function () {
			const slider4 = new Swiper(this, {
				loop: true,
				centeredSlides: true,
				watchSlidesProgress: true,
				slidesPerView: 'auto',
				// spaceBetween: 50,
				pagination: {
					el: '.swiper-pagination4',
					clickable: false,
				},
				navigation: {
					nextEl: '.swiper-button-next4',
					prevEl: '.swiper-button-prev4',
				},
				breakpoints: {
					768: {
						slidesPerView: 1.945,
					},
				},
			});
		});
	}

	if ($('.js-slide5').length) {
		$('.js-slide5').each(function () {
			const slider4 = new Swiper(this, {
				loop: true,
				centeredSlides: true,
				slidesPerView: 1.15,
				pagination: {
					el: '.swiper-pagination5',
					clickable: false,
				},
				navigation: {
					nextEl: '.swiper-button-next5',
					prevEl: '.swiper-button-prev5',
				},
				on: {
					resize: function enableOnlyMobile(swiper) {
						// Disable the slider when the window width is less than or equal to 960
						if (768 < window.innerWidth) {
							swiper.disable();
							swiper.el.classList.add('-non-slider');
						} else {
							swiper.enable();
							swiper.el.classList.remove('-non-slider');
						}
					},
				},
			});
		});
	}
});
