import $ from 'jquery';
$(window).on('load resize', function () {
	$('.js-tooltip').each(function (index) {
		let tooltip = $(this);
		if ($(window).outerWidth() > 768) {
			let target = $(this).offset().left + $(this).outerWidth();
			let content = ($(this).find('.js-tooltip-info').outerWidth() / 3) * 2;
			let wrap = $(this).closest('.l-container');
			let targetRight = wrap.offset().left + wrap.outerWidth() - target;
			let targetLeft = target - wrap.offset().left;

			if (targetRight < content) {
				$(this).find('.js-tooltip-info').css({
					left: 'auto',
					right: 0,
				});
			} else if (targetLeft < content) {
				$(this).find('.js-tooltip-info').css({
					left: 0,
				});
			}
		} else {
			let tooltipSP = $(this).find('.js-tooltip-info');
			let targetRight = tooltipSP.offset().left + tooltipSP.outerWidth();
			if (targetRight > $(window).outerWidth()) {
				let parentWidth = $(this).outerWidth();
				let tooltipWidth = tooltipSP.outerWidth();
				let leftPosition = (parentWidth - tooltipWidth) / 2;
				tooltipSP.css('left', leftPosition);
				let targetLeft = tooltipSP.offset().left + tooltipSP.outerWidth();
				if (targetLeft > $(window).outerWidth()) {
					let tru = targetLeft - $(window).outerWidth();
					tooltipSP.css('left', leftPosition - tru - 15);
				} else if (tooltipSP.offset().left < 15) {
					tooltipSP.css('left', leftPosition - tooltipSP.offset().left + 15);
				}
			}
		}

		$('.js-tooltip-open', this).on('click', function () {
			let heightTooltip = $('.js-tooltip-info', tooltip).outerHeight() + 16;

			if (tooltip.hasClass('is-open')) {
				tooltip.removeClass('is-open');
				tooltip.css('padding-bottom', 0);
			} else {
				$('.js-tooltip').removeClass('is-open');
				$('.js-tooltip').css('padding-bottom', 0);
				tooltip.addClass('is-open');
				tooltip.css('padding-bottom', heightTooltip);
			}
		});
	});

	$(document).on('click', function (event) {
		if (!$(event.target).closest('.js-tooltip').length) {
			$('.js-tooltip').removeClass('is-open');
			$('.js-tooltip').css('padding-bottom', 0);
		}
	});

	$('.js-tooltip-info').click(function (event) {
		event.stopPropagation();
	});
});
