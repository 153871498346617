import $ from 'jquery';

$(function() {
	const tabs = $('.js-tab')

	if(tabs.length) {
		tabs.each(function() {
			const _thisTab = $(this)
			const btns = _thisTab.find('[data-tab-btn]')
			const content = _thisTab.find('[data-tab]')

			btns.on('click', function() {
				btns.removeClass('is-active')
				_thisTab.find(`[data-tab-btn=${$(this).data('tab-btn')}]`).addClass('is-active')
				content.removeClass('is-active')
				_thisTab.find(`[data-tab=${$(this).data('tab-btn')}]`).addClass('is-active')
			})
		})
	}
})
