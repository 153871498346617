import $ from 'jquery';

const bodyHtml = $('body,html');
const body = $('body');

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
	scrollPosi = $(window).scrollTop();

	body.addClass('is-fixed');
	body.css({ top: -scrollPosi });
}

function bodyFixReset() {
	body.removeClass('is-fixed');
	body.removeAttr('style');
	bodyHtml.scrollTop(scrollPosi);
}

$(window).on('load', () => {
	const btnOpenModal = $('.js-open-modal');
	const btnCloseModal = $('.js-close-modal');

	function closemodal() {
		$('[data-modal]').removeClass('is-active');
		bodyFixReset();
	}

	btnOpenModal.on('click', function (e) {
		e.preventDefault();
		$(`[data-modal=${$(this).data('open-modal')}]`).addClass('is-active');
		bodyFix();
	});

	btnCloseModal.on('click', () => closemodal());

	$('[data-modal]').on('click', function (e) {
		if ($(e.target).is('.c-modal') || $(e.target).is('.c-modal__wrap')) {
			closemodal();
		}
	});

	$(window).on('scroll', () => {
		$('.c-modal.is-active').css('left', -$(window).scrollLeft());
	});
});

// Modal 2 for fun/mottainai page
$(function() {
	const modals = $('.js-modal2')

	function updateHeightModal() {
		if($(window).outerWidth() < 768) {
			$('.js-modal2-modal').css('height', $(window).innerHeight())

		} else {
			$('.js-modal2-modal').removeAttr('style')
		}
	}

	updateHeightModal()

	$(window).on('resize', () => updateHeightModal())

	$(window).on('click', function(e) {
		if($(e.target).is('.js-modal2-modal')) {
			bodyFixReset()
			$('[data-content-modal2]').removeClass('is-active')
		}
	})

	modals.each(function() {
		const _thisModal = $(this)
		const btn = _thisModal.find('.js-modal2-btn')
		const btnClose = $('.js-modal2-close')

		btn.on('click', function() {
			$('[data-content-modal2]').removeClass('is-active')
			bodyFix()
			const data = $(this).data('open-modal2')
			$(`[data-content-modal2=${data}]`).addClass('is-active')

			btnClose.on('click', function() {
				bodyFixReset()
				$('[data-content-modal2]').removeClass('is-active')
			})
		})

		$('.js-modal2-modal').on('modal-close', function () {
			$('.js-modal2-modal').removeClass('is-active')
			bodyFixReset()
		});

	})
})
