// import $ from 'jquery';
import '../Components/_Anchor.js';
import '../Components/_Slider.js';
import '../Components/_Loading.js';
import '../Components/_Lity.js';
import '../Components/_FadeIn.js';
import '../Components/_Tooltip.js';
import '../Components/_Select.js';
import '../Components/_Modal.js';
import '../Components/_Youtube.js';
import '../Components/_MatchHeight.js';
import '../Components/_popupQuestion.js';
import '../Components/_Tab.js';
import '../Components/_Accordion.js';
import '../Components/_Print.js';