import $ from 'jquery';

$(function () {
	let btn = document.getElementById('recipe-print-btn');
	if (btn) {
		btn.addEventListener('click', function () {
			window.print();
		});
	}
});
